import axios from "axios";

export default {
    namespaced: true,
    state: {
        unitReportRows: [],
        unitReportDetails: [],
        unitReportSum: [],
        filterDate: [],
        realByDays: [],
        ordersByDays: [],
        periodLength: 0,
        fieldsByGroup: [],
        listFieldsByGroup: [
            {

                key: 'product',
                label: 'Продукт',
                stickyColumn: true,
            },
            {
                group: false,
                key: 'order_chart',
                label: 'Заказы',
            },
            {
                visibility: false,
                parentVisibility: 'order_chart',
                key: 'order_amount',
                label: 'Заказы шт.',
                thStyle: {
                    background: '#dfdfe7'
                }
            },
            {
                visibility: false,
                parentVisibility: 'order_chart',
                key: 'order_summ',
                label: 'Заказы руб',
                thStyle: {
                    background: '#dfdfe7'
                }
            },
            {
                visibility: false,
                parentVisibility: 'order_chart',
                key: 'order_percent',
                label: 'Заказы %',
                thStyle: {
                    background: '#dfdfe7'
                }
            },
            {
                group: false,
                key: 'sales_chart',
                label: 'Продажи',
            },
            {
                visibility: false,
                parentVisibility: 'sales_chart',
                key: 'sales_amount',
                label: 'Выкуплено шт.',
                thStyle: {
                    background: '#dfdfe7'
                }
            },
            {
                visibility: false,
                parentVisibility: 'sales_chart',
                key: 'sales_summ',
                label: 'Выкуплено руб.',
                thStyle: {
                    background: '#dfdfe7'
                }
            },
            {
                visibility: false,
                parentVisibility: 'sales_chart',
                key: 'sales_percent',
                label: 'Выкуплено %',
                thStyle: {
                    background: '#dfdfe7'
                }
            },
            {

                key: 'averagebill',
                label: 'Ср. чек'
            },
            {

                key: 'quantity',
                label: 'Кол-во продаж',
            },
            {

                key: 'summa',
                label: 'Сумма продаж'
            },
            {

                key: 'transfer_amount',
                label: 'Перечис'
            },
            {

                key: 'pay_to_finaccount',
                label: 'К оплате на р/с'
            },
            {
                key: 'delivery',
                label: 'Стоимость логистики'
            },
            {

                key: 'penalty',
                label: 'Общая сумма штрафов'
            },
            {

                key: 'additional_payment',
                label: 'Доплаты'
            },
            {

                key: 'storage_cost',
                label: 'Стоимость хранения'
            },
            {

                key: 'storage_fee_item',
                label: 'Стоимость хранения Реал'
            },
            {

                key: 'cost_acceptance',
                label: 'Стоимость платной приемки'
            },
            {
                key: 'promo_summa',
                label: 'Реклама Счет'
            },
            {
                key: 'promo_summa_balance',
                label: 'Реклама баланс'
            },
            {
                key: 'ddr',
                label: 'ДДР'
            },
            {
                key: 'dues',
                label: 'Налоги'
            },
            {
                key: 'cost_price',
                label: 'Сумма себес.'
            },
            {
                key: 'summa_val_value',
                label: 'Вал. прибыль руб'
            },
            {
                key: 'perc_val_value',
                label: 'Вал. прибыль %'
            },
            {
                key: 'speed_sales',
                label: 'Ср. скорость продаж в день'
            },
            {
                key: 'surplus',
                label: 'Остаток'
            },
            {
                key: 'inwaytoclient',
                label: 'К клиенту'
            },
            {
                key: 'inwayfromclient',
                label: 'От клиента'
            },
            {
                key: 'forecast',
                label: 'Подсорт'
            },
            {
                key: 'sub_sort',
                label: 'Прогноз'
            },
            {
                key: 'enough_for',
                label: 'Хватит на'
            },
        ],
        subSortItems: {},
        subSortToOperation: [],
        realByDaysMax: [],
        ordersByDaysMax: [],
        registerUnitReportDetailsNmID: {}
    },

    getters: {
        getLengthsDetails(state) {
            let items = {}
            state.unitReportDetails.forEach(item => {
                if (item.length && item[0]) {
                    items[item[0].nmID] = {
                        length: item.length,
                        items: item
                    }
                }
            })
            return items
        },

        getFieldsByGroup(state) {
            return state.listFieldsByGroup.filter(field => !field.hasOwnProperty('visibility') || field.visibility === true)
        }
    },

    mutations: {
        SET_SUB_SORT_DETAILS(state, item) {
            let res;
            for (const set of state.unitReportDetails) {
                res = set.find(itemIn => +itemIn.barcode === +item.barcode);
                if (res) {
                    res.sub_sort_value = item.value
                    return
                }
            }
        },

        SET_SUB_SORT(state, item) {
            let res = state.unitReportRows.find(itemIn => +itemIn.nmID === +item.nmID);
            if (res) res.sub_sort_value = item.value
        },

        SET_UNIT_REPORTS(state, data) {
            state.unitReportRows = data
        },

        SET_UNIT_REPORTS_DETAILS(state, data) {
            let first = data[0]
            for (let j = 0; j < state.unitReportDetails.length; j++) {
                let item = state.unitReportDetails[j][0];
                if (item && +item.nmID === +first.nmID) {
                    state.unitReportDetails.splice(j, 1);
                    j--;
                }
            }
            state.unitReportDetails.push(data);
        },

        SET_SUM_UNIT_REPORTS(state, data) {
            state.unitReportSum = data
        },

        SET_DATES_FILTER(state, data) {
            state.filterDate = data
        },

        ADD_ROWS(state, {index, rows}) {
            console.log(index)
            state.unitReportRows.splice(index, 0, ...rows)
        },

        SET_REAL_BY_DAY(state, rows) {
            state.realByDays = rows
            state.periodLength = Object.entries(rows)?.length;
            state.realByDaysMax = rows.max
            delete state.realByDays.max
        },

        SET_ORDERS_BY_DAY(state, rows) {
            state.ordersByDays = rows
            state.ordersByDaysMax = rows.max
            delete state.ordersByDays.max
        },

        SET_SUB_SORT_TO_OPERATION(state, prod) {
            let i = state.subSortToOperation.findIndex(item => String(item.idL) === `${prod.nmID}${prod.barcode || ''}`);
            console.log('prod.sub_sort_value', prod.sub_sort_value);
            if (prod.sub_sort_value > 0) {
                console.log('prod.sub_sort_value !i', prod.sub_sort_value, i);
                if (i === -1) {
                    prod.idL = `${prod.nmID}${prod.barcode || ''}`;
                    state.subSortToOperation.push(prod);
                }
            } else if (i !== -1) {
                state.subSortToOperation.splice(i, 1);
            }

        },

        DEL_ROWS(state, {nmID}) {
            for (let j = 0; j < state.unitReportRows.length; j++) {
                let item = state.unitReportRows[j];
                if (item.nmID === nmID && !item.hasOwnProperty('isSizes')) {
                    console.log('nmID, item.nmID, item.hasOwnProperty(\'isSizes\')', nmID, item.nmID, item.hasOwnProperty('isSizes'));
                    state.unitReportRows.splice(j, 1);
                    j--;
                }
            }
        }
    },

    actions: {
        setVisibilityFields({state}, {parentField}) {
            state.listFieldsByGroup.forEach(field => {
                if (field.parentVisibility === parentField) field.visibility = !field.visibility
                if (field.key === parentField) field.group = !field.group
            })
        },

        async addRows({state, getters, commit, dispatch}, data) {
            await dispatch('getUnitReportDetail', data);
            //let index = state.unitReportRows.findIndex(row => row.nmID === nmID);
            //if (index !== -1 && getters.getLengthsDetails[nmID]) commit('ADD_ROWS', {rows: getters.getLengthsDetails[nmID].items, index: index + 1})
        },

        delRows({commit, getters, dispatch}, {nmID}) {
            commit('DEL_ROWS', {nmID})
        },

        clearSubSortToOperation({state, rootState}) {
            state.unitReportDetails.forEach(size => size.forEach(item => item.sub_sort_value = ''));
            state.subSortToOperation = [];
        },

        async getUnitReport({commit, dispatch}, data) {
            try {
                let unit_report_rows = await axios.post('/statistics/econ/get_unit_report', data);
                if (unit_report_rows.data?.rows) {
                    commit("SET_UNIT_REPORTS", unit_report_rows.data.rows);
                    commit('SET_SUM_UNIT_REPORTS', unit_report_rows.data.sumRow);
                    commit('SET_DATES_FILTER', unit_report_rows.data.filterDate);
                    await dispatch('getRealReportByDay', {
                        dateFrom: data.dateFrom,
                        dateTo: data.dateTo
                    });
                    await dispatch('getOrdersByDay', {
                        dateFrom: data.dateFrom,
                        dateTo: data.dateTo
                    });
                    await dispatch('unitReportScheme/getWarehouses', {
                        dateFrom: data.dateFrom,
                        dateTo: data.dateTo
                    }, { root: true });
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },


        async getRealReportByDay({commit, dispatch}, data) {
            try {
                let unit_report_rows = await axios.post('/statistics/econ/get_real_by_day', data);
                if (unit_report_rows.data) {
                    commit("SET_REAL_BY_DAY", unit_report_rows.data);
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },

        async getOrdersByDay({commit, dispatch}, data) {
            try {
                let unit_report_rows = await axios.post('/statistics/econ/get_orders_by_day', data);
                if (unit_report_rows.data) {
                    commit("SET_ORDERS_BY_DAY", unit_report_rows.data);
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },

        async getDatesReports({commit, dispatch}, data) {
            try {
                let unit_report_rows = await axios.post('/statistics/econ/get_dates_reports');
                if (unit_report_rows.data) {
                    commit('SET_DATES_FILTER', unit_report_rows.data);
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },

        async getUnitReportDetail({commit, dispatch}, data) {
            try {
                let unit_report_rows = await axios.post('/statistics/econ/get_unit_report_by_size', data);
                if (unit_report_rows.data?.rows) {
                    commit("SET_UNIT_REPORTS_DETAILS", unit_report_rows.data.rows);
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },

        async addSubSort({commit, dispatch}, item) {
            commit('SET_SUB_SORT', item)
        },

        async addSubSortDetail({commit, dispatch}, item) {
            commit('SET_SUB_SORT_DETAILS', item)
        },

        async addSubSortToOperation({commit, dispatch}, prod) {
            commit('SET_SUB_SORT_TO_OPERATION', prod)
        },

        sortByField({state, getters, commit, dispatch}, {nmID}) {
            state.unitReportDetails.sort((a, b) => {

            })
        },

        groupUnitReport({commit, dispatch}, reportRows) {
            let groupRows = {};
            let details = {};
            for (const row of reportRows) {
                if (!groupRows[row.nmID]) {
                    details[row.nmID] = []
                    groupRows[row.nmID] = {
                        isSizes: !!(row.techSize && Number(row.techSize) !== 0),
                        nmID: row.nmID,
                        _showDetails: false,
                        subjectName: row.subjectName,
                        imgMain: row.imgMain,
                        vendorCode: row.vendorCode,
                        brand: row.brand,
                        price: row.price,
                        dues: row.dues,
                        barcode: row.barcode,
                        order_amount: 0,
                        order_summ: 0,
                        sales_amount: 0,
                        sales_summ: 0,
                        quantity: 0,
                        summa: 0,
                        //averagebill: 0,
                        transfer_amount: 0,
                        delivery: 0,
                        promo_summa: 0,
                        promo_summa_balance: 0,
                        additional_payment: 0,
                        penalty: 0,
                        storage_cost: 0,
                        storage_fee_item: 0,
                        cost_acceptance: 0,
                        pay_to_finaccount: 0,
                        //cost_price: 0,
                        speed_sales: 0,
                        surplus: 0,
                        inwaytoclient: 0,
                        inwayfromclient: 0,
                        perc_val_value: 0,
                        summa_val_value: 0,
                        ddr: 0,
                        enough_for: 0,
                    }
                }
                details[row.nmID].push(row);

                let groupRow = groupRows[row.nmID];
                groupRow.order_amount += +row.order_amount
                groupRow.order_summ += +row.order_summ
                groupRow.sales_amount += +row.sales_amount
                groupRow.sales_summ += +row.sales_summ
                groupRow.quantity += +row.quantity
                groupRow.summa += +row.summa
                groupRow.transfer_amount += +row.transfer_amount
                groupRow.delivery += +row.delivery
                groupRow.promo_summa += +row.promo_summa
                groupRow.promo_summa_balance += +row.promo_summa_balance
                groupRow.additional_payment += +row.additional_payment
                groupRow.penalty += +row.penalty
                groupRow.storage_cost += +row.storage_cost
                groupRow.storage_fee_item += +row.storage_fee_item
                groupRow.cost_acceptance += +row.cost_acceptance
                groupRow.pay_to_finaccount += +row.pay_to_finaccount
                groupRow.speed_sales += +row.speed_sales
                groupRow.surplus += +row.surplus
                groupRow.inwaytoclient += +row.inwaytoclient
                groupRow.inwayfromclient += +row.inwayfromclient
                groupRow.perc_val_value += +row.perc_val_value
                groupRow.summa_val_value += +row.summa_val_value
                groupRow.ddr += +row.ddr
                groupRow.enough_for += +row.enough_for
            }
            commit("SET_UNIT_REPORTS", Object.values(groupRows));
            commit("SET_UNIT_REPORTS_DETAILS", Object.values(details));
        },
    },
}
