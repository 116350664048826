import axios from "axios";

function retypeProducts(productE) {
    let products = [];
    for (const prod of productE) {
        let name = prod.characteristic.find(item => item["Наименование"])?.["Наименование"].slice(0, 20);
        let countCh = 0;
        let parameters = {};
        for (const item of prod.characteristic) {
            [
                ["Ширина упаковки", "width"],
                ["Высота упаковки", "height"],
                ["Длина упаковки", "length"],
                ["Вес товара с упаковкой (г)", "weight"],
                ["Вес без упаковки (кг)", "weightWidthPack"],
            ].some(ch => {
                if (item.hasOwnProperty(ch[0])) {
                    parameters[ch[1]] = item[ch[0]];
                    countCh++;
                    return true;
                }
            });
            if (countCh > 5) break;
        }
        for (const nom of prod.nomenclatures) {
            let stockmp = [];
            let stockff = [];
            let sizes = false;
            let stock = {
                "ff": [],
                "readyMp": [],
                "wayMp": [],
                "onMp": []
            };

            for (const size of nom.sizes) {
                if (size.hasOwnProperty('techSize') && size.hasOwnProperty('wbSize')) {
                    stockff.push({
                        size: size.techSize || size.wbSize,
                        barcode: size.scus,
                        stock: {
                            storage: nom.stock.ff.find(st => size.scus.indexOf(st.sku) !== -1)?.amount || 0, // amount, nm_id, sku, size
                            preparation: nom.stock.readyMp.find(st => size.scus.indexOf(st.sku) !== -1)?.amount || 0,
                            wayMp: nom.stock.wayMp.find(st => size.scus.indexOf(st.sku) !== -1)?.amount || 0,
                        }
                    });
                    stockmp.push({
                        size: size.techSize || size.wbSize,
                        barcode: size.scus,
                        stock: nom.stock.onMp.find(st => size.scus.indexOf(st.sku) !== -1)?.amount || 0,
                    })
                } else {
                    sizes = [{
                        value: false,
                        barcode: nom.sizes[0]?.skus,
                        mp: nom.stock.onMp.find(st => size.scus.indexOf(st.sku) !== -1)?.amount || 0,
                        ff: nom.stock.ff.find(st => size.scus.indexOf(st.sku) !== -1)?.amount || 0,
                        preparation: nom.stock.readyMp.find(st => size.scus.indexOf(st.sku) !== -1)?.amount || 0,
                        waymp: nom.stock.wayMp.find(st => size.scus.indexOf(st.sku) !== -1)?.amount || 0,
                    }]
                }
            }

            products.push({
                id: nom.prodId,
                name,
                sebes: nom.sebes || '',
                price: nom.price,
                nmID: nom.nmID,
                imtID: nom.imtID,
                vendorCode: nom.vendorCode,
                img: nom.mediaFiles,
                imgIdent: '',
                object: nom.object,
                characteristics: nom.colors,
                brand: nom.brand,
                article: nom.vendorCode,
                parameters,
                sizes: nom.sizes,
                stockmp,
                stockff,
            })
        }
    }
    console.log(products);
    return products
}

export default {
    namespaced: true,

    state: {
        totalNumberProducts: 0,
        products: [],
        brands: [],
        objects: [],
        vendorCodes: [],
        nomenclatures: [],
        prices: [],
        loadProductsProcess: null,
        productsWidthWB: [],
        listAllBrands: [
            {value: '1', text: "Regatta"},
            {value: '2', text: "Lee"},
            {value: '3', text: "Demix"},
            {value: '4', text: "Reebok"},
            {value: '5', text: "Ikea"},
        ],
        listAllCategories: [
            {value: '1', text: "Куртки"},
            {value: '2', text: "Джинсы"},
            {value: '3', text: "Коврики для йоги"},
            {value: '4', text: "Шапки"},
            {value: '5', text: "Столы"},
        ],
        selectedProductsId: [],
        listProductsFromWb: [],
        bottomPanel: true,
        regSelectedProducts: {},
        triggerSelect: 1,
        triggerAddCheckDefect: 1,
        countCheckDefect: 0,
        triggerSetTasks: 1,
        showModalCheckDefect: false
    },

    getters: {
        getProducts(state) {
            return state.products
        },
    },

    mutations: {
        SET_PRODUCTS(store, products) {
            store.products = products.noms
            store.productsWidthWB = retypeProducts(products.noms);
            store.loadProductsProcess = true
        },

        SET_PRODUCT_ATTRIBUTES(store, {brand, object, vendorCodes}) {
            store.brands = brand;
            store.objects = object
            store.vendorCodes = vendorCodes || []
        },

        SET_PRODUCT_TOTAL_NUM(store, products) {
            store.totalNumberProducts = products.total
        },

        CLEAR_NOMENCLATURES(store) {
            store.productsWidthWB = []
        },

        ADD_TEST_PRODUCT(store, id) {
            store.testProducts.some(prod => {
                if (prod.id === id) {
                    store.products.push(prod)
                    return true
                }
            })
        },

        SET_COUNT_CHECK_DEFECT(store, count) {
            store.countCheckDefect = count
        },

        SWITCH_MODAL_CHECK_DEFECT(store) {
            store.showModalCheckDefect = !store.showModalCheckDefect
        },

        SELECT_ALL_PRODUCTS(store, val) {
            if (val === true) {
                for (const prod of store.productsWidthWB) {
                    if (!prod.hasOwnProperty(prod.id)) {
                        prod.totalAmount = '';
                        prod.shipment = {
                            amount: {},
                            checkDefect: [],
                            taskList: [],
                            totalCost: 0
                        }
                        prod.sizes.forEach(size => {
                            prod.shipment.amount[size.value] = ''
                        })
                        store.selectedProductsId.push(prod);
                        store.regSelectedProducts[prod.id] = 1;
                    }
                }
            } else if (val === false) {
                store.selectedProductsId.forEach(prod => {
                    delete prod.shipment
                    delete prod._showDetails
                });
                store.selectedProductsId = [];
                store.regSelectedProducts = {};
            }
        },

        SELECT_PRODUCTS(store, {val, productId}) {
            if (val === true) {
                let prod = store.productsWidthWB.find(prod => +prod.nmID === +productId);
                prod.totalAmount = ''
                prod.shipment = {
                    amount: {},
                    checkDefect: [],
                    taskList: [],
                    totalCost: 0
                }
                prod.sizes.forEach(size => {
                    prod.shipment.amount[size.value] = ''
                })
                store.selectedProductsId.push(prod);
                store.regSelectedProducts[prod.id] = 1;
            } else if (val === false) {
                if (store.regSelectedProducts[productId]) {
                    delete store.regSelectedProducts[productId];
                    let index = store.selectedProductsId.findIndex(prod => prod.nmID === productId);
                    let prod = store.selectedProductsId.splice(index, 1)[0];
                    if (prod) delete prod.shipment;
                }
            }
        },

        SET_TOTAL_AMOUNT(store, {val, productId}) {
            let prod = store.selectedProductsId.find(prod => +prod.id === +productId);
            if (+val > 0) prod.totalAmount = val;
            else {
                prod.totalAmount = Object.values(prod.shipment.amount).reduce((sum, num) => sum + +num, 0);
            }
            store.triggerSelect++
            store.triggerSetTasks++
        },

        SET_TRIGGER_ADD_CHECK_DEFECT(store) {
            store.triggerAddCheckDefect++
        },

        CLEAR_ALL(store) {
            store.selectedProductsId.forEach(prod => {
                delete prod.shipment
                delete prod._showDetails
            });
            store.selectedProductsId = [];
            store.regSelectedProducts = {};
        },

        ALL_TO_MP_ITEM(store, product) {
            let total = 0;
            product.sizes.forEach(size => {
                if (size.ff) {
                    product.shipment.amount[size.value] = size.ff;
                    total += size.ff;
                }
            })
        },

        SHOW_PANEL(store) {
            store.bottomPanel = true
        },

        HIDE_PANEL(store) {
            store.bottomPanel = false
        },

        DEL_TEST_PRODUCT(store, id) {
            store.products.some((prod, i) => {
                if (prod.id === id) {
                    store.products.splice(i, 1);
                    return true
                }
            });
        },

        SET_CURRENT_PAGE(store, page) {
            store.currentPage = page
        },

        SET_SEBES_PRODUCT(store, {nmId, sebes}) {
            let item = store.productsWidthWB.find(item => item.nmID = nmId);
            if (item) item.sebes = sebes;
        },

        DELETE_TASK_FROM_ALL_PRODUCTS(store, taskId) {
            store.selectedProductsId.forEach(prod => {
                let i = prod.shipment.taskList.findIndex(task => task.id === taskId);
                if (i !== -1) prod.shipment.taskList.splice(i, 1);
                prod.shipment.totalCost = prod.shipment.taskList.reduce((acc, task) => {
                    let price;
                    if (typeof task.price === "number") price = task.price;
                    else price = Object.values(task.price)[2];
                    return price + acc
                }, 0) * prod.totalAmount;
            })
            store.triggerSetTasks++
        },
        ADD_TASK_TO_ALL_PRODUCTS(store, task) {
            store.selectedProductsId.forEach(prod => {
                if (!prod.shipment.taskList.some(p => p.id === task.id)) {
                    prod.shipment.taskList.push(task);
                    prod.shipment.totalCost = prod.shipment.taskList.reduce((acc, task) => {
                        let price;
                        if (typeof task.price === "number") price = task.price;
                        else price = Object.values(task.price)[2];
                        return price + acc
                    }, 0) * prod.totalAmount;
                }
            })
            store.triggerSetTasks++
        },
        SET_PACK(store, {pack, productId}) {
            let prod = store.selectedProductsId.find(prod => prod.id === productId);
            let prev = prod.shipment.taskList.findIndex(task => task.parentName === pack.parentName)
            console.log(prev)
            if (prev !== -1) {
                prod.shipment.taskList.splice(prev, 1, pack);
            } else prod.shipment.taskList.push(pack);
            prod.shipment.totalCost = prod.shipment.taskList.reduce((acc, task) => {
                let price;
                if (typeof task.price === "number") price = task.price;
                else price = Object.values(task.price)[2];
                return price + acc
            }, 0) * prod.totalAmount;
            store.triggerSetTasks++
        },
        RECOUNT_TASKS(store, productId) {
            let prod = store.selectedProductsId.find(prod => prod.id === productId);
            prod.shipment.totalCost = prod.shipment.taskList.reduce((acc, task) => {
                let price;
                if (typeof task.price === "number") price = task.price;
                else price = Object.values(task.price)[2];
                return price + acc
            }, 0) * prod.totalAmount;
            store.triggerSetTasks++
        },
        ADD_TASK_TO_DELIVERY(store, {value, task, product}) {
            if (value && task) {
                if (!product.shipment.taskList.some(p => p.id === task.id)) {
                    product.shipment.taskList.push(task);
                    product.shipment.totalCost += task.price * product.totalAmount;
                }
            } else {
                product.shipment.taskList.splice(product.shipment.taskList.findIndex(t => t.name === task.name), 1)
                product.shipment.totalCost -= task.price * product.totalAmount;
            }
            store.triggerSetTasks++
        }
    },

    actions: {
        clearNoms({commit}) {
            commit('CLEAR_NOMENCLATURES');
        },
        switchModalCheckDefect({store, commit, dispatch}) {
            commit('SWITCH_MODAL_CHECK_DEFECT');
            dispatch('hideProductPanel');
        },
        async loadProductsAttributes({commit}) {
            try {
                let res = await axios.get('https://app.upakovan.online/nomenclatures/get_product_attributes');
                if (res.status === 200) {
                    commit('SET_PRODUCT_ATTRIBUTES', res.data);
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },
        clearSelected({commit, dispatch}) {
            commit('CLEAR_ALL');
        },
        setCountCheckDefect({commit}, count) {
            commit('SET_COUNT_CHECK_DEFECT', count);
        },
        hideProductPanel({commit}) {
            commit('HIDE_PANEL');
        },
        showProductPanel({commit}) {
            commit('SHOW_PANEL');
        },
        async selectAllProducts({commit, dispatch}, val) {
            commit('SELECT_ALL_PRODUCTS', val);
            dispatch('showProductPanel');
        },
        async selectProducts({commit, dispatch}, {val, productId}) {
            commit('SELECT_PRODUCTS', {val, productId});
            dispatch('showProductPanel');
        },
        async setTotalAmount({commit, dispatch}, {val, productId}) {
            commit('SET_TOTAL_AMOUNT', {val, productId});
            commit('RECOUNT_TASKS', productId);
        },
        async addTestProduct({state, commit, dispatch}, productId) {
            commit('ADD_TEST_PRODUCT', productId);
            dispatch("currentSupply/addProductsToSupply", state.products, {root: true});
        },
        async delTestProduct({commit}, productId) {
            commit('DEL_TEST_PRODUCT', productId);
        },
        async setTriggerAddCheckDefect({commit}, productId) {
            commit('SET_TRIGGER_ADD_CHECK_DEFECT', productId);
        },
        allFromFfToMpItem({commit, dispatch}, product) {
            commit('ALL_TO_MP_ITEM', product);
            dispatch('setTotalAmount', {productId: product.id})
        },
        allFromFfToMp({state, dispatch},) {
            state.selectedProductsId.forEach(product => {
                dispatch('allFromFfToMpItem', product)
            })
        },
        addTaskToAllProducts({commit}, task) {
            commit('ADD_TASK_TO_ALL_PRODUCTS', task);
        },
        delTaskFromAllProducts({commit}, taskId) {
            commit('DELETE_TASK_FROM_ALL_PRODUCTS', taskId);
        },
        setPacking({commit, dispatch}, {pack, productId}) {
            commit('SET_PACK', {pack, productId});
        },
        addTaskToDelivery({commit, dispatch, rootState}, {value, taskName, product}) {
            let task = rootState.services.listServices.find(serv => serv.name === taskName);
            commit('ADD_TASK_TO_DELIVERY', {value, task, product});
        },
        async loadNomenclatures({commit, dispatch}, data) {
            try {
                let res = await axios.get('https://app.upakovan.online/ff/get_products', {
                    params: {
                        find: data?.find || '',
                        sort: data?.sort || ['', true],
                        page: data?.page || 0,
                    }
                })
                if (res.status === 200) {
                    commit('SET_PRODUCTS', res.data.products);
                    commit('SET_PRODUCT_TOTAL_NUM', res.data.products);
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },
        setPage({commit}, page) {
            commit('SET_CURRENT_PAGE', page);
        },
        async sendSebes({commit, state, dispatch}, {nmID, costPrice}) {
            try {
                console.log(nmID, costPrice)
                if (!costPrice) return false;
                let products = await axios.post('https://app.upakovan.online/nomenclatures/set_sebes', {
                    nmID,
                    costPrice
                });
                if (products.status === 200) {
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },
        async reloadNomenclaturesAll({commit, dispatch}, data) {
            try {
                let products = await axios.get('https://app.upakovan.online/ff/reload_nomenclatures')
                if (products.status === 200) {
                    dispatch('loadNomenclatures')
                    return true
                }
            } catch (e) {
                console.log(e)
            }
            return false
        },
    },
}
