import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import products from './products'
import services from './services'
import unitReport from './unit-report'
import unitReportScheme from './unitReportScheme'
import currentSupply from './currentSupply'
import verticalMenu from './vertical-menu'
import calculator from './calculator'
import keys from './keys'
import user from './user'
import orders from './orders'
import shipments from './shipments'
import delivery from './delivery'
import contractors from './contractors'
import purchase from './purchase'
import nomenclatures from './nomenclatures'
import econ from './econ'
import pnl from './pnl'
import charts from './charts'
import purchaseItem from './purchaseItem'

Vue.use(Vuex)

export default new Vuex.Store({
    actions: {
        clearSelectedNmId({dispatch}) {
            dispatch('clearSelectedNmId')
        }
    },
    modules: {
        app,
        shipments,
        delivery,
        appConfig,
        products,
        services,
        charts,
        verticalMenu,
        econ,
        pnl,
        contractors,
        unitReport,
        unitReportScheme,
        currentSupply,
        nomenclatures,
        purchaseItem,
        calculator,
        keys,
        purchase,
        orders,
        user
    },
    strict: process.env.DEV,
})
